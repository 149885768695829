import React, { useState, useRef, useEffect, useContext } from 'react';
import { IonButtons, IonButton, IonModal, IonHeader, IonContent, IonToolbar, IonTitle, IonPage, IonItem, IonLabel, IonInput, IonIcon, useIonToast } from '@ionic/react';
import { OverlayEventDetail } from '@ionic/core/components';
import { checkmarkCircleOutline, personOutline } from 'ionicons/icons';
import firebase from '../data/fireConfig';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import ModalHeader from './ModalHeader';
import { UserContext } from '../App';



function Login(props: any) {
  const modal = useRef<HTMLIonModalElement>(null);
  const input = useRef<HTMLIonInputElement>(null);
  const [shouldPresent, setShouldPresent] = useState(false)
  const [present] = useIonToast();

  function confirm() {
    modal.current?.dismiss(input.current?.value, 'confirm');
  }

  function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
    if (ev.detail.role === 'confirm') {
    }
  }
  const user = useContext<any>(UserContext as any);
  useEffect(() => {
    if(user) {
      if(shouldPresent) present({message:"successfully connected!", duration: 2000, position:"top", icon:checkmarkCircleOutline, translucent:true});
      setShouldPresent(false);
      modal.current?.dismiss()
    };
  }, [user])
  


  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    //
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    //signInSuccessUrl: '/signedIn',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      {
        provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,//props.firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        defaultCountry: "DE",
        recaptchaParameters: {
          size: 'invisible'
        },
        // whitelistedCountries: ['BE', 'BJ', 'CM', 'CA', 'TD','CN','CD', 'CG','CI', 'DK', 'DJ', 'FR', 'DE', 'GH', 'GN', 'GW', 'IT', 'KE', 'LU', 'ML', 'NG', 'SN', 'ES', 'TG','UA', 'GB', 'US', 'NL', 'DZ', ZW]
      }
    ],
  };

  return (

    <IonModal onWillPresent={() => setShouldPresent(true)} swipeToClose={true} ref={modal} trigger={props?.trigger}>
      <ModalHeader title="Connect to MDC" dismiss={() => modal.current?.dismiss()} />
      <IonContent className="ion-padding">
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      </IonContent>
    </IonModal>
  );
}

export default Login;