import React from 'react';
import { IonDatetime, IonDatetimeButton, IonModal } from '@ionic/react';
function DateTimePicker() {
  return (
    <>
    <IonDatetimeButton datetime="datetime"></IonDatetimeButton>
    
    <IonModal keepContentsMounted={true}>
      <IonDatetime id="datetime" presentation="date-time"></IonDatetime>
    </IonModal>
    </>
  );
}
export default DateTimePicker;