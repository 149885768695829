import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemDivider, IonLabel, IonList, IonModal, IonPage, IonTitle, IonToolbar, useIonToast } from '@ionic/react'
import { Widget } from '@uploadcare/react-widget'
import React, { useRef, useState } from 'react'
import { useFormspark } from "@formspark/use-formspark"
import { checkmarkCircle, checkmarkCircleOutline } from 'ionicons/icons';
import ModalHeader from '../../components/ModalHeader';

function ContactModel(props:any) {
  const modal = useRef<HTMLIonModalElement>(null);
  const [submit, submitting] = useFormspark({formId: "54g5ebiI"});
  const [present] = useIonToast();
  const [nom, setNom] = useState("")
  const [email, setEmail] = useState("")
  const [instagram, setInstagram] = useState("")
  const [tel, setTel] = useState("")
  const [photo, setPhoto] = useState("")
 
  const sendInfo = async (e:React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      present({message:"your infos have been sent", duration: 2000, position:"top", icon:checkmarkCircleOutline, translucent:true})
      await submit({
                Categorie: "Candidate",
                Nom: nom,
                Email: email,
                instagram: instagram,
                tel: tel,
                profil: photo,
      })
      setNom(""); setEmail(""); setInstagram(""); setTel("")
      
  }
  return (
    <IonModal swipeToClose={true} ref={modal} trigger={props?.trigger}>
      <ModalHeader title="Devenir top Model" dismiss={() => modal.current?.dismiss()} />
      <IonContent>
        <form onSubmit={(e) => sendInfo(e)}>
        <IonList style={{ marginLeft: "5px" }}>
          <IonItemDivider>Informations generales</IonItemDivider>
          <IonItem>
            <IonLabel position="stacked">Nom(s) et Prenom(s)</IonLabel>
            <IonInput placeholder='your name' type='text' minlength={4} autocomplete='name'  required value={nom} onIonChange={e => setNom(e.detail.value!)}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Email</IonLabel>
            <IonInput placeholder='youremail@email.com' type="email" autocomplete='email' required value={email} onIonChange={e => setEmail(e.detail.value!)}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Instagram</IonLabel>
            <IonInput placeholder='inta username' type='text' minlength={4} required value={instagram} onIonChange={e => setInstagram(e.detail.value!)}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Tel</IonLabel>
            <IonInput placeholder='+49157...' type='tel' autocomplete='tel' minlength={5} required value={tel} onIonChange={e => setTel(e.detail.value!)}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Photo profile</IonLabel>
            <Widget onChange={(file) => setPhoto(file.originalUrl || file.cdnUrl!)} publicKey='8431c83393453c254d9d' />
          </IonItem>
        </IonList>
        <IonButton  type="submit" expand="block">S'enregistrer</IonButton>
        </form>
      </IonContent>
      
    </IonModal>
  )
}

export default ContactModel