import { IonModal, IonText, useIonToast } from '@ionic/react'
import React, { useRef, useState } from 'react'
import ModalHeader from './ModalHeader'
import { PayPalButtons } from '@paypal/react-paypal-js';
import { checkmarkCircleOutline, closeCircleOutline, warningOutline } from 'ionicons/icons';

function Paypalpayement(props: any) {
    const modal = useRef<HTMLIonModalElement>(null);
    const [shouldPresent, setShouldPresent] = useState(false)
    const [present] = useIonToast(); 

    const createOrder = (data:any, actions:any) => {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: props?.price,
              },
            },
          ],
        });
      }

    const presentOptions = (message:string, icon:any=null, position:string = "top", duration=1500) => ({message, duration, position, icon, translucent:true})
    
    const onApprove = (data:any, actions:any) => {
      if(!props?.takeOnServer) {
        actions.order.capture().then(function () {
          present({...presentOptions("Payment Successfull", checkmarkCircleOutline) as any})
        });
      } else {
        present({...presentOptions("Sending payment", checkmarkCircleOutline) as any})
      }
      props?.onApprove(data.orderID) 
      modal.current?.dismiss()
    }

    const onCancel = () => {
      present({...presentOptions("Payment Canceled", warningOutline) as any}) 
    }

    const onError = () => {
      present({...presentOptions("Payment Error", closeCircleOutline) as any}) 
    }

      


    return (
        <IonModal onWillPresent={() => setShouldPresent(true)} swipeToClose={true} ref={modal} trigger={props?.trigger}>
            <ModalHeader title="Finish payment" dismiss={() => modal.current?.dismiss()} />
            <IonText className='ion-text-center'><h5>{props?.title}</h5></IonText>
            <PayPalButtons
                createOrder={(data, actions) => createOrder(data, actions)}
                onApprove={(data, actions) => onApprove(data, actions) as any}
                onCancel={(data, actions) => onCancel() as any}
                onError={(err) => onError() as any}
      />
        </IonModal>
    )
}

export default Paypalpayement