import { IonAvatar, IonButton, IonButtons, IonCard, IonCardHeader, IonCardSubtitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonMenuButton, IonNav, IonNavLink, IonPage, IonRow, IonText, IonTitle, IonToolbar, useIonToast } from '@ionic/react'
import { add, checkmarkCircleOutline, closeCircleOutline, cloudUploadOutline, eyeOutline, heart, heartOutline, personOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { BsStars } from 'react-icons/bs'
import { useLocation, useParams } from 'react-router';
import { UserContext } from '../../../App';
import Login from '../../../components/Login';
import Paypalpayement from '../../../components/Paypalpayement';
import { auth, func } from '../../../data/fireConfig';
import ReservationGala from '../../contact/ReservationGala'
import ArticleDetails from '../../detail/ArticleDetails';
import CandidateDetails from '../../detail/CandidateDetails';

export const getAwardVotes = (missId: number, votes: any[], paidVotes: any[], keyname:string = "missId") => {
    // var totalPaidVotes = paidVotes?.filter(vote => vote.missId == missId).length * 5;
    var totalvotes = votes?.filter(vote => vote[keyname] == missId).length
    return totalvotes;
}

const showStatus = (status: any) => {


    if (status?.pause) {
        return <IonText className='ion-text-center'>
           <p>Les Votes continuent dans un Instant !  <br /> Votes will continue soon !</p> 
        </IonText>
    }
    if (status?.after === true ) {
        return <IonText className='ion-text-center'>
          <p>Fin! Merci pour Votre Participation ! <br /> End! Thanks for your participation!</p>  
        </IonText>
    }
    if (status?.vor === true ) {
        return <IonText className='ion-text-center'>
           <p>Les Votes commencent bientôt! <br /> Votes are starting soon!</p> 
        </IonText>
    }
    if (!status || !status.during) {
        return <IonText className='ion-text-center'>
         <p>Soit pret pour les votes! <br /> Be ready for votes!</p>
        </IonText>
    }

}

const shouldHide = (status:any) => {
    return !status || status?.pause || status?.block || status?.after || status?.vor || !status.during;
}

function VoteAwardView(props: any) {
    const { misses, status, votes, paidVotes } = props;
    const user = useContext<any>(UserContext as any);
    const [present] = useIonToast();
    var userChoice = user ? votes?.find((vote: any) => vote?.id == user?.uid)?.missId || 0 : 0;
    let location = useLocation();
    console.log("")
    useEffect(() => {
        var path = location.pathname.split('/');
        if (path.length >= 4) {
            const body = document.getElementById(`miss${path[3]}`);
            const content: HTMLIonContentElement | null = document.querySelector(`ion-content#misscontent`)
            content?.scrollToPoint(0, body?.offsetTop, parseInt(path[3]) * 10)
        }
        return () => { }
    }, [misses])

    console.log(status);
    

    const addChoice = (missId: number) => {
        present({ message: "adding your vote!", duration: 1000, position: "top", icon: cloudUploadOutline, translucent: true });
        const normalVote = func.httpsCallable('normalVote');
        normalVote({ missId })
            .then((result: any) => present({ message: "Vote successfully added!", duration: 1500, position: "top", icon: checkmarkCircleOutline, translucent: true }))
            .catch((err) => present({ message: "vote could not be added, please try again", duration: 1500, position: "top", icon: closeCircleOutline, translucent: true }));
    }

    const addPaidVote = (missId: number, orderID: any, value: number) => {
        present({ message: "adding your vote!", duration: 1000, position: "top", icon: cloudUploadOutline, translucent: true });
        const normalVote = func.httpsCallable('paidVote');
        normalVote({ missId, isPaid: true, orderId: orderID, value })
            .then((result: any) => present({ message: `${value} votes successfully added!`, duration: 1500, position: "top", icon: checkmarkCircleOutline, translucent: true }))
            .catch((err) => present({ message: "vote could not be added, please try again", duration: 1500, position: "top", icon: closeCircleOutline, translucent: true }));
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons>
                        <IonMenuButton />
                        <IonTitle>Miss Vote </IonTitle>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent scrollEvents={true} id="misscontent">
                <IonGrid>
                    <IonRow>
                        <IonCol class="ion-text-center">
                            <IonText>
                                <p> <IonText color='primary'><BsStars /></IonText>  La final du concours se fera au Gala <IonText color='primary'><BsStars /></IonText></p>
                            </IonText>
                            <IonNavLink routerDirection="forward" component={() => <ReservationGala />}>
                                <IonButton color="secondary" style={{ align: "center" }}>Reserver ma place pour le Gala</IonButton>
                            </IonNavLink>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                {misses && misses.map((miss: any) => <IonCard id={"miss" + miss.missId} key={miss.id}>
                    <div id={"div" + miss.id}>
                        <IonItem>
                            <IonAvatar slot="start">
                                <img src={miss?.img?.formats?.thumbnail?.url} />
                            </IonAvatar>
                            <IonLabel>
                                <h2>{miss?.name}</h2>
                                <p>{miss.categorie} {miss?.missId}</p>
                            </IonLabel>
                        </IonItem>
                        <img src={miss.img?.formats?.medium?.url || miss?.image?.url} />
                        <CandidateDetails participante={miss} trigger={"div" + miss.id} origin="votemiss" />
                    </div>
                    <IonCardHeader className={shouldHide(status) ? "ion-hide":""}>
                        <IonCardSubtitle color="tertiary">{getAwardVotes(miss?.missId, votes, paidVotes, miss.categorie+"Id" )} Votes</IonCardSubtitle>
                    </IonCardHeader>
                    <IonRow style={{ marginTop: "-10px" }} className={shouldHide(status) ? "ion-hide":""}>
                        <IonCol size='5'>
                            {user && <IonButton onClick={() => addChoice(miss?.missId)} fill='clear'>
                                <IonIcon icon={userChoice == miss?.missId ? heart : heartOutline} />
                                <div>&nbsp;vote</div>
                            </IonButton>}
                            <> <IonButton id={"loginmiss" + miss?.id} fill='clear' className={user ? "ion-hide" : ""}>
                                <IonIcon icon={personOutline}></IonIcon>
                                Connect to vote
                            </IonButton>
                                <Login type="miss" dataId={miss?.missId} trigger={"loginmiss" + miss?.id} />
                            </>
                        </IonCol>
                        <IonCol size='6'>
                            {user && <>
                                <IonButton id={'miss' + miss?.missId + 'addfivevote'} color="tertiary" fill='clear'>
                                    <IonIcon icon={add} />
                                    <div>&nbsp;add 5 votes 5€</div>
                                </IonButton>
                                <Paypalpayement
                                    trigger={'miss' + miss?.missId + 'addfivevote'}
                                    title={"5€ add 5 votes for miss N°" + miss?.missId}
                                    price="5.00"
                                    onApprove={(orderID: any) => addPaidVote(miss?.missId, orderID, 5)}
                                />
                            </>}
                        </IonCol>
                        <IonCol size="5" style={{ marginTop: "-20px" }}>
                            <IonButton id={"button" + miss.id} color="secondary" fill='clear'>
                                <IonIcon icon={eyeOutline} />
                                <div>&nbsp;open</div>
                            </IonButton>
                            <CandidateDetails participante={miss} trigger={"button" + miss.id} origin="votemiss" />
                        </IonCol>
                        {/* <IonCol size='6' style={{ marginTop: "-20px" }}>
                            {user && <>
                                <IonButton id={'miss' + miss?.missId + 'addtenvote'} color="tertiary" fill='clear'>
                                    <IonIcon icon={add} />
                                    <div>&nbsp;add 10 votes 10€</div>
                                </IonButton>
                                <Paypalpayement
                                    trigger={'miss' + miss?.missId + 'addtenvote'}
                                    title={"10€ add 10 votes for miss N°" + miss?.missId}
                                    price="10.00"
                                    onApprove={(orderID: any) => addPaidVote(miss?.missId, orderID, 10)}
                                />
                            </>}
                        </IonCol> */}
                    </IonRow>
                    <IonRow style={{ marginTop: "-10px" }} className={!shouldHide(status) ? "ion-hide":""}>
                        <IonCol className='ion-text-center'>
                            {showStatus(status)}
                            <IonButton style={{marginTop:"-1em"}} id={"buttonstatus" + miss.id} color="secondary" fill='clear'>
                                <IonIcon icon={eyeOutline} />
                                <div>&nbsp;open my Gallery</div>
                            </IonButton>
                            <CandidateDetails participante={miss} trigger={"buttonstatus" + miss.id} origin="votemiss" />
                        </IonCol>
                    </IonRow>

                </IonCard>)}
            </IonContent>


        </IonPage>
    )
}



export default VoteAwardView