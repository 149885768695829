import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonModal, IonPage, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react'
import React, { useRef } from 'react'
import { useParams } from 'react-router';
import { useGetDataRequest } from '../../data/request';
import LightGallery from 'lightgallery/react';
import { arrowForwardOutline, chevronDown, chevronDownOutline } from 'ionicons/icons'

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';


// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import ModalHeader from '../../components/ModalHeader';


function CandidateDetails(props:any) {
  const modal = useRef<HTMLIonModalElement>(null);
  const onInit = () => {
    console.log('lightGallery has been initialized by kapygenius', props?.participante);
  };
  const participante = props.participante
  const trigger = props.trigger

  return (
    <IonModal swipeToClose={true} ref={modal} trigger={trigger}>
      <ModalHeader dismiss={() => modal.current?.dismiss()} title={"Miss "+participante?.missId + ": " +participante?.name} />
      <IonContent>
        <img src={participante?.img?.formats?.medium?.url} />
        <IonText >
          <div style={{ marginLeft: "10px", marginRight: "10px" }}>
            <h4>{participante?.name}</h4>
            <IonText color="primary"><small>Miss {participante?.missId}</small></IonText>
            <p>{participante?.description}</p>
          </div>
        </IonText>
        {props?.origin && props?.origin == "votemiss" ? <IonButton onClick={() => modal.current?.dismiss()} expand="block">Votes pour moi</IonButton> : <IonButton href={'/votemiss/vote/'+participante?.missId} expand="block">Votes pour moi</IonButton>}
        <div style={{marginTop:"10px"}}>
              <LightGallery
                onInit={onInit}
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
              >
                {participante && participante?.gallery?.map((gal: any, index:number) => <div key={index} style={{ width:"40%", display:"inline-block", marginRight:"2%", marginLeft:"5%"}} data-src={gal?.formats?.medium?.url || gal?.url}>
                  <a href={gal?.formats?.medium?.url|| gal?.url}>
                    <img alt={"miss" + participante?.missId + ": " + participante?.name} src={gal?.formats?.medium?.url || gal?.url} />
                  </a>
                  </div>
                )}
              </LightGallery>
        </div>
      </IonContent>
    </IonModal>
  )
}

export default CandidateDetails